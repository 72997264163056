<template>
  <div class="font-template">
    <b-card
      v-for="(atuacao, index) in atuacoes" :key="index"      
      :sub-title="atuacao.is_principal | toFirstOrSec"     
    >
      <template v-slot:header>
        <h5 class="mb-0" v-text="atuacao.area_atuacao"></h5>
      </template>

      <b-card-text>
        <p><strong>Subáreas:</strong></p>        
        <ul>
          <li v-for="(subarea, index) in atuacao.subareas_atuacao" :key="index"><em>{{ subarea }}</em></li>      
        </ul>    
      </b-card-text> 

      <b-card-text>
        <p><strong>Mais informações:</strong></p>
        {{ atuacao.outras_informacoes }}
      </b-card-text>
    </b-card>
  </div>
</template>


<script>
export default {
  name: 'prof-card-atuacao-component',
  props: {
    atuacoes: {
      type: Array
    }
  },
  data: () => ({

  }),
  filters: {
    toFirstOrSec (value) {
      if(value) {
        return 'Atuação principal'
      } else {
        return 'Atuação secundária'
      }
    }
  }
}
</script>

<style scoped>

.card-card_header {
  font-size: 2em;
  font-weight: 500;
  text-shadow: 2px 2px #333;  
}
.card-card_footer {
  font-size: 2em;
  font-weight: 500;
  text-shadow: 2px 2px #333;
}
.card-card_title {
  font-size: 2em;
  font-weight: 500;
  text-shadow: 2px 2px #333;
}




.font-template {
  font-family: montserrat;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}

li {
  color: #3cbca5;
}
</style>


