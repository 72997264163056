import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import axios from 'axios'

// Make Axios play nice with Django CSRF
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

/* usar localStorage para amarzenar os dados para persistir se houver alguma
   atualização da página até a conclusão do cadastro. Enquanto ele não for encerrado
   e enviado o usuário poderá atualizar a página e reiniciar todo o processo.

   Para isso, será necessário usar flags chave para controlar o fluxo do processo.
*/

Vue.use(Vuex)

export default new Vuex.Store({  
  state,
  mutations,
  actions,
  getters
})
