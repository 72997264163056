const state = {  
  profile: localStorage.getItem('profile'),
  userId: localStorage.getItem('userId'),
  isAuthenticated: false,
  isActivatedAccount: localStorage.getItem('isActivatedAccount'),
  isCompletedRegistration: false,
  propertyCode: localStorage.getItem('propertyCode')
}

export default state
