import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import router from "./routes/index";
import store from "./store/index";
import "./plugins/bootstrap-vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueMask from "v-mask";
import("@/assets/css/tailwind.css");
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ThemifyIcon from "vue-themify-icons";
import VCalendar from "v-calendar";
import Unicon from "vue-unicons";
import {
    uniInstagramAlt,
    uniFacebook,
    uniTwitter,
    uniWhatsappAlt,
    uniLinkedin,
    uniEnvelope,
    uniPhone,
    uniMobileAndroid,
    uniGlobe,
    uniMapMarker,
    uniBuilding,
    uniHardHat,
    uniSchedule,
} from "vue-unicons/src/icons";
// Fortawesome
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
// VeeValidate v3 libs
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,
} from "vee-validate";
import ptBr from "vee-validate/dist/locale/pt_BR.json";
import * as rules from "vee-validate/dist/rules";

// Bootstrap Vue
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMask);
// vue-form-wizard
Vue.use(VueFormWizard);
Vue.use(ThemifyIcon);

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});
localize("pt-BR", ptBr);
// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.use(VCalendar);

// Vue Unicons
Unicon.add([
    uniInstagramAlt,
    uniFacebook,
    uniTwitter,
    uniWhatsappAlt,
    uniLinkedin,
    uniEnvelope,
    uniPhone,
    uniMobileAndroid,
    uniGlobe,
    uniMapMarker,
    uniBuilding,
    uniHardHat,
    uniSchedule,
]);
Vue.use(Unicon);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
