<template>
  <div>
    <b-container>
      <b-row align-v="centre" align-h="center">
        <h2>{{ title }}</h2>
      </b-row>
    </b-container>  
  </div>
</template>

<script>
export default {
  name: 'error_404',
  data: () => ({
    title: 'Erro 404 - Página ou recurso não encontrados...'
  })
}
</script>

<style scoped>  
</style>