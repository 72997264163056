<template>
  <div>

    <b-modal
      v-model="modalTermoShow"
      hide-footer
      scrollable title="TERMO DE USO DO GUIA"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <p class="my-4">
        O CRMV-GO <strong>NÃO</strong> se responsabiliza pelos serviços prestados pelas empresas cadastradas no Guia Profissional, mas comunica que todas estão registradas no CRMV-GO.
      </p>
      <p class="my-4">Você aceita o termo?</p>
      <b-button class="button-send" @click="hideModal">Sim</b-button>
      <b-button class="ml-2 button-clear" @click="navigateTo('/')">Não</b-button>
    </b-modal>

    <div class="card search">
      <div class="card-header text-center card-header-custom">
        <h4
          class="title-card"
        >PESQUISE AQUI POR EMPRESAS ATUANTES NAS ÁREAS DE MEDICINA-VETERINÁRIA E ZOOTECNIA</h4>
      </div>
      <div class="card-body">
        <form>
          <div class="form-row">
            <div class="form-group col-md-4 mb-0">
              <p>
                <label class="label-text" for="id_nome_completo">Nome da empresa</label>                
                <input
                  type="text"
                  class="form-control"
                  v-model="form.nome_completo"
                  id="id_nome_completo"
                />
              </p>
            </div>

            <div class="form-group col-md-2 mb-0">
              <p>
                <label class="label-text" for="id_propriedade">Propriedade</label>                
                <select
                @change="loadAreas"
                  class="form-control"
                  name="propriedade"
                  v-model="form.propriedade"
                >
                  <option                    
                    v-for="(propriedade, index) in optionsPropriedade"
                    :key="index"
                    :value="propriedade.value"
                  >{{ propriedade.text }}</option>
                </select>
                <span class="info-note">Selecionar propriedade antes</span>
              </p>
            </div>

            <div class="form-group col-md-3 mb-0">
              <p>
                <label class="label-text" for="id_area_atuacao">Área de atuação</label>

                <select
                  @change="loadSubareas"
                  class="form-control"
                  name="area_atuacao"
                  v-model="form.area_atuacao"
                >
                  <option                    
                    v-for="area in optionsAreas"
                    :key="area.id"
                    :value="area.id"
                  >{{ area.nome }}</option>
                </select>
                
              </p>
            </div>

            <div class="form-group col-md-3 mb-0">
              <p>
                <label class="label-text" for="id_subarea_atuacao">Subárea</label>
                <select class="form-control" id="id_subarea_atuacao" name="subarea_atuacao" v-model="form.subarea_atuacao">
                  <option
                    v-for="subarea in optionsSubareas"
                    :key="subarea.id"
                    :value="subarea.id"
                  >{{ subarea.nome }}</option>
                </select>
              </p>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6 mb-0">
              <p>
                <label class="label-text" for="id_cidade">Escolha a cidade</label>
                <select class="form-control" name="cidade" v-model="form.cidade">
                  <option
                    v-for="cidade in optionsCidades"
                    :key="cidade.id"
                    :value="cidade.id"
                  >{{ cidade.nome }}</option>
                </select>
              </p>
            </div>

            <div class="form-group col-md-6 mb-0">
              <p>
                <label class="label-text" for="id_bairro">Bairro</label>
                <input type="text" v-model="form.bairro" id="id_bairro" class="form-control" />
              </p>
            </div>
          </div>

          <div class="row">            
             <div class="col-sm">
              <b-button-group>
                <b-button class="button-send" @click.prevent="sendToEmpresaResults">
                  <b-icon icon="search"></b-icon>
                  Pesquisar
                </b-button>
                <b-button class="button-clear" @click.prevent="formClear">
                  <b-icon icon="trash"></b-icon>
                  Apagar busca
                </b-button>
              </b-button-group>
            </div>
          </div>

        </form>
      </div>
      <div class="card-footer text-muted">
        <span class="text-footer">Resultados:</span>
      </div>
    </div>
  </div>
</template>


<script>
// import Multiselect from 'vue-multiselect'
import axios from 'axios'
import { BASE_URL } from '../../../services/http-common'

export default {
  name: 'form-search-prof-component',
  data: () => ({    
    form: {
      nome_completo: '',
      area_atuacao: '',
      subarea_atuacao: '',
      cidade: '',
      bairro: '',
      propriedade: '',
    },
    optionsAreas: [],
    optionsSubareas: [],
    optionsCidades: [],
    optionsPropriedade: [            
      { value: '', text: '' },
      { value: 1, text: 'Pública' },
      { value: 2, text: 'Privada' },
      { value: 4, text: 'Pública e Privada' }      
    ],
    modalTermoShow: true    
  }),
  mounted() {
    this.loadCidades()
  },
  methods: {    
    loadCidades() {
      // Carrega cidades do estado de Goiás
      axios.get(`${BASE_URL}/cadastro/ajax/cidades/?uf=GO`, {})
        .then(response => {      
          this.optionsCidades = response.data['data']        
        })
        .catch((error) => {
          console.log(error) 
        })
        .finally(() => {        
        })
    },
    loadAreas() {
      // Carrega áreas      
      this.optionsSubareas = []
      axios.get(`${BASE_URL}/cadastro/ajax/empresa/areas/?propriedade=${this.form.propriedade}`, {})
        .then(response => {      
          this.optionsAreas = response.data['data']
        })
        .catch((error) => {
          console.log(error) 
        })
        .finally(() => {        
        })
    },
    hideModal() {
      this.modalTermoShow = false
    },
    loadSubareas () {
      // Carrega Subáreas
      axios.get(`${BASE_URL}/cadastro/ajax/empresa/subareas/?area=${this.form.area_atuacao}`, {})
        .then(response => {      
          this.optionsSubareas = response.data['data']        
        })
        .catch((error) => {
          console.log(error)  
        })
        .finally(() => {
        })
    },
    formClear() {
      this.form.nome_completo = ''
      this.form.area_atuacao = ''
      this.form.subarea_atuacao = ''
      this.form.cidade = ''
      this.form.bairro = ''
      this.form.propriedade = ''
      this.optionsSubareas = []

      this.$root.$emit('cleanResults');
    },
    sendToEmpresaResults() {
      if(this.form.propriedade == undefined) {
        this.form.propriedade = ''
      }
      this.$root.$emit('loadFormDataEmpresa', this.form)              
    },
    navigateTo(page) {
      this.$router.push(page).catch(() => {})
    }
  },
};
</script>


<style scoped>
.search {
  margin: 20px 0 30px 0;
  font-family: montserrat;
}
.card-header-custom {
    background-color: #3cbca5;
}
.title-card {
  text-transform: uppercase;
  color: #f4f0e3;
  font-size: 1.2em;
}
.btn-search {
  margin-top: 15px;
  margin-bottom: 15px;
}
.label-text{
  font-weight: bold; 
}

.trash-btn {
  background-color: #ff4d4d;
}
.trash-btn:hover {
  background-color: #af2929;
}

.info-note {
  font-size: 11px;
  font-style: italic;
}

.button-clear {
  color: #f4f0e3;
  background: grey;
  border: grey;
}

.button-clear:hover {  
  background: rgb(109, 108, 108);  
}

.button-send {
  color: #f4f0e3;
  background: #3cbca5;
  border: #3cbca5;
}

.button-send:hover {  
  background: #547c71;
}

</style>