<template>
  <div class="font-template">    
    <b-modal v-model="modalShow" hide-footer title="CADASTRO">
      <b-overlay :show="showOverlay" rounded="sm">
        <div class="text-center" :aria-hidden="showOverlay ? 'true' : null">
          <p class="h3 mb-4">
            <img src="../assets/img/person.png" alt="Imagem usuario">
          </p>
          <div v-if="!successView" class="d-block text-center modal-title font-template">
            <h6>Se você é um profissional Médico-Veterinário/Zootecnista ou representa uma empresa destas áreas, faça seu cadastro no <strong>Guia Profissional do CRMV-GO</strong>!</h6>
          </div>

          <div v-if="successView" class="font-template">
            <h6>
              <strong>Parabéns!</strong>
            </h6>
            <h6>Cadastro realizado com sucesso!</h6>
            <p class="h2 mb-2">              
              <img src="../assets/img/email_sent.png" alt="">
            </p>
            <p>Por favor, abra seu e-mail, confirme seu registro e complete seu cadastro!</p>
            <b-button class="ml-2 button-clear" @click="hideModal()">Fechar</b-button>
          </div>

          <div v-if="!successView">
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(onSubmit)" class="font-template">
                <!-- Nome do usuário -->
                <validation-provider
                  name="Nome"
                  :rules="{ required: true, min: 4, regex:/^[a-zA-Z-\d]+$/, uniqueUsername: true }"
                  v-slot="validationContext"
                >
                  <b-form-group id="example-input-group-1" label-for="example-input-1">
                    <span style="font-size: 12px;">O nome de usuário só pode conter letras e números (sem espaços)</span>
                    <b-form-input
                      class="inputs"
                      id="example-input-1"
                      name="example-input-1"
                      v-model="form.name"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                      placeholder="Nome do usuário (login)"
                    ></b-form-input>

                    <b-form-invalid-feedback
                      id="input-1-live-feedback"
                    >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- E-mail -->
                <validation-provider
                  name="E-mail"
                  :rules="{ required: true, email: true, uniqueUserMail: true }"
                  v-slot="validationContext"
                >
                  <b-form-group id="example-input-group-2" label-for="example-input-2">
                    <b-form-input
                      class="inputs"
                      type="email"
                      id="example-input-2"
                      name="example-input-2"
                      v-model="form.email"                    
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-2-live-feedback"
                      placeholder="E-mail"
                    ></b-form-input>

                    <b-form-invalid-feedback
                      id="input-2-live-feedback"
                    >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- Senha -->
                <validation-provider
                  name="Senha"                               
                  :rules="{ required: true, regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ }"
                  v-slot="validationContext"
                >
                  <b-form-group id="example-input-group-3" label-for="example-input-3">
                    <b-form-input
                      class="inputs"
                      type="password"
                      id="example-input-3"
                      name="password"
                      v-model="form.password"                    
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-3-live-feedback"
                      placeholder="Senha"
                      vid="password"
                    ></b-form-input>

                    <b-form-invalid-feedback
                      id="input-3-live-feedback"
                    >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                
                <!-- Confirmação da Senha -->
                <validation-provider
                  name="Confirma senha"                
                  :rules="{required: true, passwdconfirm: form.password}"
                  v-slot="validationContext"
                >
                  <b-form-group id="example-input-group-4" label-for="example-input-4">
                    <b-form-input
                      class="inputs"
                      type="password"
                      id="example-input-4"
                      name="example-input-4"
                      v-model="form.password2"                    
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-4-live-feedback"
                      placeholder="Confirma senha"                      
                    ></b-form-input>

                    <b-form-invalid-feedback
                      id="input-4-live-feedback"
                    >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-button class="button-send" type="submit" variant="primary">Enviar</b-button>
                <b-button class="ml-2 button-clear" @click="resetForm()">Limpar</b-button>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>


<script>
import axios from 'axios'
import { extend } from 'vee-validate'
import { regex } from 'vee-validate/dist/rules'
import { BASE_URL } from '../services/http-common'

extend('regex', {
  ...regex,
  message: 'Senha deve ter no mínimo de 8 caracteres, pelo menos uma letra maiúscula, uma letra minúscula e um número.',
})

extend('passwdconfirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'As senhas não são iguais!'
});

extend('uniqueUsername', {  
  async validate(value) {
    let usernameNotExist = true
    await axios.get(`${BASE_URL}/cadastro/ajax/username/?username=${value}`, {})
    .then(response => {      
      usernameNotExist = !response.data.existe_username      
    })
    .catch(error => {
      console.log(error)
    })
    return usernameNotExist
  },
  message: 'Este nome de usuário já está em uso.'
});

extend('uniqueUserMail', {  
  async validate(value) {
    let emailNotExists = true
    await axios.get(`${BASE_URL}/cadastro/ajax/email/?email=${value}`, {})
    .then(response => {      
      emailNotExists = !response.data.existe_email      
    })
    .catch(error => {
      console.log(error)
    })
    return emailNotExists
  },
  message: 'Esse e-mail já está cadastrado'
});


export default {
  name: "register-component",
  components: {},
  data: () => ({     
    form: {
      name: null,
      email: null,
      password: null,
      password2: null,
    },
    showOverlay: false,
    successView: false,
    modalShow: false
  }),
  mounted() {
    this.$root.$on('activeModal', () => {
       this.showModal()
     })
  },
  methods: {
    showModal() {
      // this.$refs["modal-register-form"].show();
      this.modalShow = true
      this.showOverlay = false
      this.successView = false
      this.form.name = ''  
    },
    hideModal() {
      // this.$refs["modal-register-form"].hide();
      this.modalShow = false
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        name: null,
        email: null,
        password: null,
        password2: null,
      }
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onSubmit() {
      this.$store.commit('CLEAN_DATA_REGISTER')
      this.showOverlay = true;
      axios({
        method: "post",
        url: `${BASE_URL}/api/jwtauth/signup/`,
        data: {
          username: this.form.name,
          email: this.form.email,
          password: this.form.password,
        }
      })
      .then(response => {                    
        if(response.status == 201) {
          this.successView = true
        }
      })
      .catch(error => {
        if(error.response.status == 400){          
          alert('O usuário não foi criado!')
        }
        if(error.response.status == 404){
          alert('Recurso não encontrado!')
        }
        if(error.response.status == 422){
          //fazer outra coisa
        }        
      })
      .finally(() => {
        this.showOverlay = false        
      });
      // this.resetForm()     
    }
  }
}
</script>


<style scoped>
.font-template {
  font-family: montserrat;
}

img {
  max-width: 100px;
}

.inputs {
  /* padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 5px;
  margin-top: 2px;
  font-size: 14px; */
  text-align: center;
}

.button-send {
  color: #f4f0e3;
  background: #3cbca5;
  border: #3cbca5;
}

.button-send:hover {  
  background: #547c71;
}

.button-clear {
  color: #f4f0e3;
  background: grey;
  border: grey;
}

.button-clear:hover {  
  background: rgb(109, 108, 108);  
}

</style>