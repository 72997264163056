const mutations = {
    SET_PROFILE(state, profile) {
      state.profile = profile
      localStorage.setItem('profile', profile)
    },
    SET_USER_ID(state, id) {
      state.userId = id
      localStorage.setItem('userId', id)
    },
    SET_IS_AUTHENTICATED(state, flag) {
      state.isAuthenticated = flag      
    },
    SET_IS_ACTIVATED_ACCOUNT(state, flag) {
      state.isActivatedAccount = flag
      localStorage.setItem('isActivatedAccount', flag)
    },
    SET_IS_COMPLETED_REGISTRATION(state, flag) {
      state.isCompletedRegistration = flag        
    },
    CLEAN_DATA_REGISTER(state) { 
      state.userId = 0
      state.isAuthenticated = false
      state.isActivatedAccount = false      
      state.propertyCode = 0     
      localStorage.removeItem('profile')
      localStorage.removeItem('userId')
      localStorage.removeItem('isAuthenticated')
      localStorage.removeItem('isActivatedAccount')
      localStorage.removeItem('propertyCode')      
    },
    SET_PROPERTY_CODE(state, property) {
      state.propertyCode = property
      localStorage.setItem('propertyCode', property)
    },
  }
  
  export default mutations
  