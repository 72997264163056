<template>
  <div>
    <main>
      <div class="container-fluid quadrado">
        <div class="row">
          <div class="md-12">
            <img class="img-fluid logos" src="../assets/img/logos2.png" />
          </div>          
        </div>          

        <div class="row justify-content-md-end blocos1">
          
          <div class="col">
            <div class="welcome">
                <h3>Seja bem-vindo!</h3>
                <p class="text-justify">O <strong>Guia Profissional</strong> é uma plataforma de divulgação criada pelo Conselho Regional de Medicina Veterinária e Zootecnia de Goiás (<a href="https://www.crmvgo.org.br" target="_blank">CRMV-GO</a>) para que as empresas e profissionais registrados no Conselho possam ofertar seus serviços à sociedade, e assim sejam facilmente encontrados pelos potenciais clientes.</p>                  
            </div>                       
          </div>
          
          <router-link :to="{name: 'searchprofissional'}">
            <div class="md-6 bl">
              <div @mouseover="mouseFlagProfissional = !mouseFlagProfissional" @mouseout="mouseFlagProfissional = false">
                <img v-if="!mouseFlagProfissional" class="img-fluid p_1" src="../assets/img/p1.png" />              
                <img v-else class="img-fluid p_1" src="../assets/img/p1_texto.png" />              
              </div>
              <img class="img-fluid p_2 mobile-icon" src="../assets/img/p1_1.png" />
            </div>
          </router-link>

          <router-link :to="{name: 'searchempresa'}">
            <div class="md-6 blc">
              <div @mouseover="mouseFlagEmpresa = !mouseFlagEmpresa" @mouseout="mouseFlagEmpresa = false">
                <img v-if="!mouseFlagEmpresa" class="img-fluid p_1" src="../assets/img/p2.png" />
                <img v-else class="img-fluid p_1" src="../assets/img/p2_texto.png" />
              </div>
              <img class="img-fluid p_2 mobile-icon" src="../assets/img/p1_2.png" />
            </div>
          </router-link>
          
        </div>

        <div class="row justify-content-md-end blocos2">
          <div class="md-6 bl" @click="showRegisterModal">
            <div @mouseover="mouseFlagCadatro = !mouseFlagCadatro" @mouseout="mouseFlagCadatro = false">
              <img v-if="!mouseFlagCadatro" class="img-fluid p_1" src="../assets/img/p4.png"/>
              <img v-else class="img-fluid p_1" src="../assets/img/p4_texto.png"/>
            </div>
            <img class="img-fluid p_2 mobile-icon" src="../assets/img/p1_4.png"/>
          </div>          
          <div class="md-6 blc" @click="showNewsModal">
            <div @mouseover="mouseFlagBoletim = !mouseFlagBoletim" @mouseout="mouseFlagBoletim = false">
              <img v-if="!mouseFlagBoletim" class="img-fluid p_1" src="../assets/img/p3.png"/>
              <img v-else class="img-fluid p_1" src="../assets/img/p3_texto.png"/>
            </div>
            <img class="img-fluid p_2 mobile-icon" src="../assets/img/p1_3.png"/>
          </div>          
        </div>
        
      </div>

      <b-modal ref="modal-contact-form" hide-footer title="BOLETIM INFORMATIVO">
        <b-overlay :show="showOverlay" rounded="sm">          
          <div class="modal-text text-center" :aria-hidden="showOverlay ? 'true' : null">
            <p class="h1 mb-2">
              <b-icon icon="envelope-open-fill" style="color: #3cbca5;"></b-icon>
            </p>
            <div class="d-block text-center modal-title">
              <h6 text-center>Increva-se e seja comunicado das ações do CRMV-GO!</h6>
            </div>

            <div v-if="successView">
              <h6><strong>Parabéns!</strong></h6>
              <h6>Cadastro realizado com sucesso!</h6>
              <b-button class="ml-2" squared @click="hideModal()">Fechar</b-button>
            </div>

            <div v-if="!successView">
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)">   
    
                <!-- Nome completo -->
                  <validation-provider
                    name="Nome completo"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group id="example-input-group-2" label-for="example-input-1">
                      <b-form-input
                        class="inputs"
                        type="text"
                        id="example-input-1"
                        name="example-input-1"
                        v-model="form.name"                    
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                        placeholder="Nome completo"
                      ></b-form-input>

                      <b-form-invalid-feedback
                        id="input-1-live-feedback"
                      >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                <!-- E-mail -->
                  <validation-provider
                    name="E-mail"
                    :rules="{ required: true, email: true, uniqueNewsMail: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group id="example-input-group-2" label-for="example-input-2">
                      <b-form-input
                        class="inputs"
                        type="email"
                        id="example-input-2"
                        name="example-input-2"
                        v-model="form.email"                    
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-2-live-feedback"
                        placeholder="E-mail"
                      ></b-form-input>

                      <b-form-invalid-feedback
                        id="input-2-live-feedback"
                      >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>  

                <b-button class="button-send" type="submit" variant="primary">Enviar</b-button>
                <b-button class="ml-2 button-clear" @click="resetForm()">Limpar</b-button>
              </b-form>
              </validation-observer>
            </div>
          </div>
        </b-overlay>        
      </b-modal>      
      <register-component></register-component>
    </main>
    <footer-component></footer-component>
  </div>
</template>

<script>
import FooterComponent from "../components/Layouts/FooterComponent"
import RegisterComponent from '../components/RegisterComponent'
import { extend } from 'vee-validate'
import axios from "axios"
import { BASE_URL } from '../services/http-common'

extend('uniqueNewsMail', {  
  async validate(value) {
    let emailNotExists = true
    await axios.get(`${BASE_URL}/api/v1/newsletter/search_email/?email=${value}`, {})
    .then(response => {      
      emailNotExists = !response.data.email_exist      
    })
    .catch(error => {
      console.log(error)
    })
    return emailNotExists
  },
  message: 'Este e-mail já está cadastrado.'
});

export default {
  name: "home",
  components: {
    FooterComponent,
    RegisterComponent
  },
  data: () => ({
    form: {
      nome: "",
      email: ""
    },
    contactResponse: "",
    showOverlay: false,
    successView: false,
    emailExist: false,       
    errors: [],
    mouseFlagProfissional: false,
    mouseFlagEmpresa: false,
    mouseFlagCadatro: false,
    mouseFlagBoletim: false,
  }),
  methods: {
    showRegisterModal() {
      this.$root.$emit('activeModal')
    },
    showNewsModal() {
      this.$refs["modal-contact-form"].show();
      this.showOverlay = false
      this.successView = false
      this.emailExist = false
      this.form.name = ''
      this.form.email = ''
    },
    hideModal() {
      this.$refs["modal-contact-form"].hide();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        name: null,
        email: null
      }
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onSubmit() {
      this.showOverlay = true;

      axios.post(`${BASE_URL}/api/v1/newsletter/`,{ 
        nome: this.form.name,
        email: this.form.email
      })
      .then(response => {                    
        if(response.status == 201) {
          this.successView = true
        }
      })
      .catch(error => {
        if(error.response.status == 400){          
          alert('O contato não foi criado!')
        }
        if(error.response.status == 404){
          alert('Recurso não encontrado!')
        }
        if(error.response.status == 422){
          //fazer outra coisa
        }        
      })
      .finally(() => {
        this.showOverlay = false        
      });
      // this.resetForm()     
    }
  }
};
</script>


<style scoped>
@media (max-width: 621px) {
  .logos {
    margin-left: 0px;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  .quadrado {
    margin-top: 30px;
    margin-left: 0px;
    margin-bottom: 200px;
  }

  .blocos1 {
    margin-bottom: 20px;
  }
  .bl {
    padding-left: 25px;
    margin-bottom: 20px;
  }
  .blc {
    padding-left: 25px;
  }  
  .p_1 {
    display: none;
    margin-right: 20px;    
  }
  .mobile-icon {
    margin-left: 0px;
    max-width: 310px;    
  }
  
}
@media (min-width: 622px) {
  .logos {
    padding-left: 15px;
    padding-bottom: 20px;
  }
  .quadrado {
    margin-bottom: 100px;
  }
  .blocos1 {
    margin-bottom: 10px;
    padding-right: 30px;
  }
  .blocos2 {
    padding-right: 30px;
  }
  .bl {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .blc {
    padding-left: 20px;
  }
  .p_2 {
    display: none;    
    margin-left: auto;
  }

  /* .images:hover {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .images:active {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    box-shadow: 0 5px #8d8b8b;
    transform: translateY(4px);
  } */

  .text-title {
    text-align: center;
    font-size: 1.3em;
  }

  .modal-text {
    font-family: montserrat;
  }

  .modal-title {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .send-btn {
    margin-bottom: 15px;
  }

  .close-btn {
    float: right;
  }

  .inputs {
    /* padding: 8px 15px 8px 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 5px;
    margin-top: 2px;
    font-size: 14px; */
    text-align: center;
  }

}
.welcome {
  font-family: montserrat;
  font-weight: 900;
  max-width: 450px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  color: dimgrey;  
}

.welcome h3{
  color: #547c71;
  font-weight: 900;
  font-size: 1.9em;
}
.welcome p{
  color: #3cbca5;
  font-size: 1.3em;
  line-height: 125%;
}

.welcome strong{
  color: #547c71;   
}

.welcome a{
  color:#547c71;
  transition: color 0.2s;
}

.welcome a:hover{
  color:#ffb84d;
  text-decoration: none;
}

.button-send {
  color: #f4f0e3;
  background: #3cbca5;
  border: #3cbca5;
}

.button-send:hover {  
  background: #547c71;
}

.button-clear {
  color: #f4f0e3;
  background: grey;
  border: grey;
}

.button-clear:hover {  
  background: rgb(109, 108, 108);  
}
</style>