<template>
  <div>    
    <b-container>
      <form-search-prof-component></form-search-prof-component>    
      <prof-results-component></prof-results-component> 
    </b-container>    
  </div>
</template>


<script>
import FormSearchProfComponent from '../components/Search/Profissional/FormSearchProfComponent'
import ProfResultsComponent from '../components/Search/Profissional/ProfResultsComponent'

// Usado como container para comunicação entre componentes
export default {
  name: "search-profissional",  
  components: {    
    FormSearchProfComponent,
    ProfResultsComponent
  },
  data: () => ({    
  })
}
</script>


<style scoped>

</style>
