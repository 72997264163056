import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import AccountActivate from '../views/AccountActivate.vue'
import ResendingActivationEmail from '../views/ResendingActivationEmail.vue'
import SearchProfissional from '../views/SearchProfissional.vue'
import SearchEmpresa from '../views/SearchEmpresa.vue'
import Error404 from '../views/Error404.vue'

Vue.use(Router)

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      name: 'home'
    },
    {
      path: '/activate',
      component: AccountActivate,
      name: 'activate'
    },
    {
      path: '/resending-activation-email',
      component: ResendingActivationEmail,
      name: 'resending-activation-email'
    },
    {
      path: '/pesquisa/profissional',
      component: SearchProfissional,
      name: 'searchprofissional'
    },
    {
      path: '/pesquisa/empresa',
      component: SearchEmpresa,
      name: 'searchempresa'
    },
    {
      path: '*',
      component: Error404}
  ]    
})

