<template>
  <div>    
    <b-container>
      <form-search-empresa-component></form-search-empresa-component>    
      <empresa-results-component></empresa-results-component> 
    </b-container>    
  </div>
</template>

<script>
import FormSearchEmpresaComponent from '../components/Search/Empresa/FormSearchEmpresaComponent'
import EmpresaResultsComponent from '../components/Search/Empresa/EmpresaResultsComponent'

// Usado como container para comunicação entre componentes
export default {
  name: "search-empresa",  
  components: {    
    FormSearchEmpresaComponent,
    EmpresaResultsComponent
  },
  data: () => ({
    formData: null
  }),
  methods: {
    sendToSearch (data) {
      this.formData = data
    }
  }
}
</script>


<style scoped>

</style>
