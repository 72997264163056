<template>
  <div class="font">
    <b-container>
      <b-row>
        <b-col class="flexbox">
          <AlertsComponent
            v-if="message"
            class="alerts"
            :type="type"
            :message="message"
          ></AlertsComponent>
        </b-col>          
      </b-row>

      <b-row>
        <b-col class="flexbox">
          <b-overlay :show="showOverlay" rounded="sm">
            <b-card bg-variant="Default" :aria-hidden="showOverlay ? 'true' : null" class="text-center card">
              <b-card-title class="card-title">Recuperação de link para completar cadastro</b-card-title>
              <b-card-text>

                <b-form @submit.prevent="onSubmit()">

                  <b-form-group>
                    <b-form-input
                      required
                      class="input"
                      type="email"                   
                      v-model="email"                                                              
                      placeholder="E-mail"
                    ></b-form-input>
                  </b-form-group>               

                  <b-button class="button-send ml-2" variant="primary" type="submit">Enviar</b-button>
                  <b-button class="button-clear ml-2" type="reset">Limpar</b-button>                  
                </b-form>                            
              </b-card-text>              
            </b-card>
          </b-overlay>
        </b-col>          
      </b-row>
    </b-container>  
  </div>
</template>

<script>

import AlertsComponent from '../components/AlertsComponent';
import axios from 'axios';
import { BASE_URL } from '../services/http-common';

export default {
  name: 'resending-activation-email',
  components: {
    AlertsComponent,   
  },
  data: () => ({
    email: '',
    showOverlay: false,
    message: '',
    type: ''
  }),
  mounted() {

  },
  computed: {
    siteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    }
  },
  methods: {    
    onSubmit() {
      this.showOverlay = true
      this.message = ''
      this.type = ''

      axios.post(`${BASE_URL}/api/jwtauth/resending_activation/`,
        {
          email: this.email
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((response) => {        
        if(response.status == 200) {
          // Mensagem
          this.message = `O link para completar cadastro enviado com sucesso para o endereço de e-mail ${this.email}!`
          this.type = 'success'
        }
      })
      .catch((error) => {        
        switch(error.response.status) {
          case 400:
            this.message = `Erro ao enviar o link para completar cadastro para o endereço de e-mail <strong>${this.email}</strong>!`
            this.type = 'danger'            
            break;
          case 404:
            this.message = `Não existe usuário com o endereço de e-mail <strong>${this.email}</strong> cadastrado!`
            this.type = 'warning'            
            break;
          case 406:
            this.message = `O(a) usuário(a) vinculado(a) ao endereço de e-mail <strong>${this.email}</strong> já completou seu cadastro!`
            this.type = 'warning'           
            break;
          case 409:
            this.message = 'Um usuário administrador do Guia Profissional não pode ter cadastro como profissional ou empresa!'
            this.type = 'warning'           
            break;
          case 500:
            this.message = 'Erro no servidor! Por favor, tente mais tarde!'
            this.type = 'danger'           
            break;
        
          default:
            break;
        }
      })
      .finally(() => {
        this.showOverlay = false        
      })
    }
  }
}
</script>

<style scoped>
.font {
  font-family: montserrat;
}

@media screen and (max-width: 576px) {
  .input {
    text-align: center;
    font-size: 1.4em;    
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 576px) {
  .flexbox { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
  }

  .alerts {
    width: 540px;  
  }

  .card {
    width: 540px;
    height: 200px;  
  } 

  .card-title {
    font-size: 1.3em;
  }
  .input {
    text-align: center;
    font-size: 1.2em;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.button-send {
  color: #f4f0e3;
  background: #3cbca5;
  border: #3cbca5;
}

.button-send:hover {  
  background: #547c71;
}

.button-clear {
  color: #f4f0e3;
  background: grey;
  border: grey;
}

.button-clear:hover {  
  background: rgb(109, 108, 108);  
}
</style>