import axios from 'axios'
// import authHeader from './auth-header'
import { BASE_URL } from './http-common'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const API_URL = BASE_URL + '/api/v1/profileclick/'

class ProfileClick {
  new(data) {
    return axios.post(API_URL, data)
  }

}

export default new ProfileClick()