<template>
  <div class="font-template">
    <b-button @click="getDetailEmpresa" block size="sm" class="button">{{ buttonTitle }}</b-button>

    <b-modal v-model="modalShow" ok-only centered size="lg" :title="empresa.nome_completo">      
      
      <b-overlay :show="isLoadin" rounded="sm">
        <b-tabs
          content-class="mt-3"
          :aria-hidden="isLoadin ? 'true' : null"
          active-nav-item-class="font-weight-bold text-uppercase text-danger"          
        >
          <b-tab active>
            <template v-slot:title>
              <b-icon icon="building"></b-icon> A Empresa
            </template>
            <b-row>
              <b-col md="2" sm="2" class="img-arrange">
                <b-img
                  class="img-custom"
                  fluid-grow
                  thumbnail
                  right
                  rounded
                  :src="`${BASE_URL}/media/${empresa.logo}` | defaultImage"
                  alt="Imagem de pessoa padrão"
                ></b-img>
              </b-col>
              <b-col md="10" sm="10" class="font-template">
                <h6><i class="fas fa-building fa-1x mr-1"></i> <strong>Sobre a empresa:</strong></h6>
                <p style="margin-bottom: 20px">                
                  {{ empresa.sobre_empresa }}
                </p>

                <h6 style="margin-bottom: 20px"><i class="fas fa-user-shield fa-1x mr-1"></i> <strong>Propriedade:</strong> {{ propriedade }}</h6>

                <h6><i class="far fa-calendar-alt"></i> <strong>Dias e  horários de funcionamento:</strong></h6>
                <div class="days-hours">
                  <div v-for="(day, index) in empresa.working_days_hours" :key="index">                    
                    <i v-if="day.is_open" class="far fa-calendar-check calendar-color"></i>
                    <i v-else class="far fa-calendar"></i>
                    <span>
                      <strong> {{ day.name }}</strong>, {{ day.is_open ? 'aberto' : 'fechado' }}
                      <span v-if="day.is_open && day.open_time != '24:00'">
                        das {{ day.open_time }}h até {{ day.closed_time }}h</span>                                 
                        <span v-if="day.open_time == '24:00'">{{ day.open_time }}h</span>
                    </span>
                  </div>
                </div>

                <div v-for="(endereco, index) in empresa.enderecos" :key="index">
                  <div v-if="endereco.is_public">
                    <h6><i class="fas fa-map-marked-alt"></i> <strong>Endereço:</strong></h6>
                    <p>
                      <strong>Logradouro:</strong>
                      {{ endereco.logradouro }}
                    </p>
                    <p v-if="endereco.complemento">
                      <strong>Complemento:</strong>
                      {{ endereco.complemento }}
                    </p>
                    <p v-if="endereco.numero">
                      <strong>Número:</strong>
                      {{ endereco.numero }}
                    </p>
                    <p>
                      <strong>Bairro:</strong>
                      {{ endereco.bairro }}
                    </p>
                    <p>
                      <strong>Cidade:</strong>
                      {{ endereco.cidade.nome }}
                    </p>
                    <p>
                      <strong>UF:</strong>
                      {{ endereco.estado.uf }}
                    </p>
                    <p v-if="endereco.local_googlemaps"> 
                      <span class="social-data">                                            
                        <a target="_blank" :href="endereco.local_googlemaps"><unicon name="map-marker" fill="red" height="50px" width="50px"></unicon></a>
                      </span>
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab @click="sendClick('PR', empresa.id)">
            <template v-slot:title>
              <b-icon icon="briefcase-fill"></b-icon> Atividades
            </template>
            <b-row class="font-template">
              <b-col>
                <empresa-card-atuacao-component
                  :atuacoes="empresa.atuacoes_empresariais"                
                ></empresa-card-atuacao-component>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <b-icon icon="at"></b-icon>Contato
            </template>

            <b-row class="font-template">

              <b-col sm="4">
                <span class="social-data" v-if="empresa.telefone_celular">
                    <a @click="sendClick('SP', empresa.id)" target="_balnk" :href="`tel:${ empresa.telefone_celular }`">
                      <i class="fas fa-mobile fa-1x mr-1"></i>
                      {{ empresa.telefone_celular | nullToString | VMask('(##) # ####-####') }}
                    </a>
                  </span>
              </b-col>

              <b-col sm="4">
                <span class="social-data" v-if="empresa.telefone_fixo">
                    <a @click="sendClick('PH', empresa.id)" target="_balnk" :href="`tel:${ empresa.telefone_fixo }`">
                      <i class="fas fa-phone fa-1x mr-1"></i>
                      {{ empresa.telefone_fixo | nullToString | VMask('(##) ####-####') }}
                    </a>    
                </span>
              </b-col>
            
              <b-col sm="4">
                <span class="social-data" v-if="empresa.whatsapp">
                    <a @click="sendClick('WA', empresa.id)" target="_balnk" :href="`https://wa.me/55${ empresa.whatsapp }`">
                      <i class="fab fa-whatsapp-square fa-1x mr-1"></i>
                      {{ empresa.whatsapp }}
                    </a>
                </span>
              </b-col>

            </b-row>              

            <b-row class="font-template">

              <b-col sm="6">
                <span class="social-data" v-if="empresa.email_comercial">
                  <a @click="sendClick('EM', empresa.id)" :href="`mailto:${empresa.   email_comercial}`">
                    <i class="fas fa-envelope fa-1x mr-1"></i>{{ empresa.email_comercial }}
                  </a>
                </span>
              </b-col>

              <b-col sm="6">
                <span class="social-data" v-if="empresa.website">                  
                  <a @click="sendClick('WS', empresa.id)" target="_blank" :href="empresa.website">
                    <i class="fas fa-globe fa-1x mr-1"></i>{{ empresa.website }}
                  </a>
                </span>
              </b-col>

            </b-row>

            <b-row>
              <b-col>              
                <span class="social-data" v-if="empresa.linkedin">
                  <a @click="sendClick('LE', empresa.id)" target="_blank" :href="empresa.linkedin">
                    <i class="fab fa-linkedin fa-2x mr-1"></i>
                  </a>
                </span>
                      
                <span class="social-data" v-if="empresa.facebook">
                  <a @click="sendClick('FB', empresa.id)" target="_blank" :href="empresa.facebook">
                    <i class="fab fa-facebook fa-2x mr-1"></i>
                  </a>
                </span>
                     
                <span class="social-data" v-if="empresa.instagram">
                  <a @click="sendClick('IG', empresa.id)" target="_blank" :href="empresa.instagram">
                    <i class="fab fa-instagram fa-2x mr-1"></i>
                  </a>
                </span>
                      
                <span class="social-data" v-if="empresa.twitter">
                  <a @click="sendClick('TW', empresa.id)" target="_blank" :href="empresa.twitter">
                    <i class="fab fa-twitter fa-2x mr-1"></i>
                  </a>
                </span>
                           
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Avaliação">
            <template v-slot:title>
              <b-spinner type="grow" small></b-spinner>
              <strong>Avalie-me!</strong>
            </template>
            <b-row>
              <b-col>
                <evaluation-component
                  title="Compartilhe sua experiência para contribuir na melhoria da prestação do serviço da empresa*:"
                  info="*Comunicamos que essa avaliação é anônima e ficará disponível apenas para a empresa e CRMV-GO. Além disso, este não é um canal de denúncia."
                  :client-id="empresa.id"                
                ></evaluation-component>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

      </b-overlay>

      <profile-click
        ref="profileClick"
      ></profile-click>

    </b-modal>
  </div>
</template>

<script>
import EmpresaCardAtuacaoComponent from './EmpresaCardAtuacaoComponent'
import EvaluationComponent from '../EvaluationComponent'
import ProfileClick from '../../ProfileClick'
import axios from 'axios'
import { BASE_URL } from '../../../services/http-common'

const urlImgEmpresa = BASE_URL + '/static/website/images/corporativo.png'

export default {
  name: 'empresa-detail-component',
  components: {
    EvaluationComponent,    
    EmpresaCardAtuacaoComponent,
    ProfileClick
  },
  props: {
    empresaId: {
      type: Number,
      required: true,
      default: 0
    },
    buttonTitle: {
      type: String,
      default: 'Clique aqui'
    }
  },
  data: () => ({
    BASE_URL: BASE_URL,
    modalShow: false,
    empresa: {
      user: {
        email: ''
      }
    },
    enderecos: '',
    isLoadin: false
  }),
  methods: {
    sendClick(element, client) {      
      this.$refs.profileClick.sendForm(element, client)      
    },
    getDetailEmpresa() {      
      this.modalShow = !this.modalShow;
      this.isLoadin = true;
      // busca a empresa
      axios.get(
          `${BASE_URL}/api/v1/empresas-detail/${this.empresaId}/`,
          {}
        )
        .then(response => {
          this.empresa = response.data;    
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadin = false;
        });      
    }
  },
  computed: {
    propriedade() {
      let text = ''
      if(this.empresa.propriedade != undefined || this.empresa.propriedade != null) {
        switch (this.empresa.propriedade) {
          case 1:
            text = 'Pública'
            break;
          case 2:
            text = 'Privada'
            break;
          case 4:
            text = 'Pública e Privada'
            break;  
        }
      }
      return text
    }
  },
  filters: {
    defaultImage(value) {
      if (value) {
        return value;
      } else {
        return urlImgEmpresa;
      }
    },
    nullToString(value) {
      if (value == null) {
        return '';
      } else {
        return value;
      }
    }
  }
}
</script>

<style scoped>

.button {
  color: #3cbca5;
  background: #fff;
  border: 1px solid #3cbca5;
}

.button:hover {
  color: #fff;
  background: #3cbca5;
  border: 1px solid #3cbca5;
}

a {
  color: #3cbca5;
  transition: color 0.2s;
}
a:hover {
  color: #ffb84d;
  text-decoration: none;
}

.font-template {
  font-family: montserrat;
}

p {
  margin: 5px 5px 0px 0px;
}

.img-arrange {
  text-align: center;  
}

@media screen and (max-width: 576px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 110px;
    width: 50%;
  }  
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }  
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

.social-data {
  margin: 10px 10px 10px 15px;
}

.days-hours {
  margin: 15px 0px 15px 0px;
  font-size: 0.9em;
}

.calendar-color {
  color: #278a6c;
}

.text-color {
  color: blue;
}

</style>
