<template>
  <div class="font-template vld-parent">

    <loading :active.sync="isLoadin" 
      :can-cancel="true"       
      :is-full-page="true"
      color="#000"
      loader="spinner"
    ></loading>

    <!-- Alertas -->
    <alerts-component     
      v-if="successIsActive"     
      type="success"      
      :message="pagination.count > 1 ? `${pagination.count} profissionais encontrados` : `${pagination.count} profissional encontrado`"
    ></alerts-component>

    <alerts-component     
      v-if="warningIsActive && notName"     
      type="warning"
      results=''
      :message="`Não houve cadastros de profissionais com o nome ''${formData.nome_completo}''.`"
    ></alerts-component>

    <alerts-component     
      v-if="warningIsActive && !notName"     
      type="warning"
      results=''
      message="Não houve cadastros de profissionais no guia para este número de registro, profissão, área, cidade ou bairro selecionados."
    ></alerts-component>

    <alerts-component     
      v-if="dangerIsActive"     
      type="danger"
      results=''
      message="Erro! Algo falhou... Por favor, tente mais tarde!"
    ></alerts-component>  

    <p v-show="searchIsActive">Página: <b>{{ currentPage }}</b></p >   
    <!-- Exibição dos resultados da pesquisa -->
    <div v-show="searchIsActive" class="row results" id="id-results">
      <prof-card-resume-component
        v-for="(profissional, index) in profissionais"
        :key="index"
        :profissional="profissional"
      ></prof-card-resume-component>
    </div>

    <!-- Paginação -->    
    <b-pagination class="pagination"
      v-show="searchIsActive"
      v-model="currentPage"
      @input="loadPages"
      :total-rows="pagination.count"
      :per-page=6      
      first-text="Primeira"
      prev-text="Anterior"
      next-text="Próxima"
      last-text="Última"
    ></b-pagination>   

  </div>
</template>


<script>
import ProfCardResumeComponent from "./ProfCardResumeComponent"
import AlertsComponent from '../../AlertsComponent'
import Loading from 'vue-loading-overlay'    
import 'vue-loading-overlay/dist/vue-loading.css'
import axios from 'axios'
import { BASE_URL } from '../../../services/http-common'

export default {
  name: "prof-results-component",  
  components: {
    ProfCardResumeComponent,
    AlertsComponent,
    Loading
  },
  data: () => ({
    profissionais: [],
    isLoadin: false,
    searchIsActive: false,
    successIsActive: false,
    dangerIsActive: false,
    warningIsActive: false,
    pagination: {
      next: null,
      previous: null,
      count: null,
      page: null,
      num_page: null
    },
    formData: null,
    currentPage: 1,
    resultsCleaned: false
  }),
  mounted() {
    // EventBus    
    this.$root.$on('loadFormDataProfissional', (data) => {
      this.formData = data
      this.loadProfResume()   
     })

    this.$root.$on('cleanResults', () => {
      this.successIsActive = false
      this.searchIsActive = false
      this.warningIsActive = false
      this.profissionais = []  
     })
  },
  computed: {
    notName() {
      if(this.formData.nome_completo) {
        return true
      }
      return false
    }
  },
  methods: {
    axiosParams () {
      const params = new URLSearchParams()
      params.append('nome', this.formData.nome_completo)      
      params.append('crmv', this.formData.crmv)      
      params.append('profissao', this.formData.profissao)      
      params.append('area', this.formData.area_atuacao)
      params.append('subarea', this.formData.subarea_atuacao)
      params.append('cidade', this.formData.cidade)
      params.append('bairro', this.formData.bairro)     
      return params
    },
    loadProfResume () {
      this.isLoadin = true    
      this.successIsActive = false
      this.warningIsActive = false
      axios.get(`${BASE_URL}/api/v1/profissionais-resume/`, {params: this.axiosParams()})
      .then(response => {
        this.profissionais = response.data.results

        // const { results } = response.data;
        // console.log(results);


        this.pagination.next = response.data.next              
        this.pagination.previous = response.data.previous              
        this.pagination.count = response.data.count              
        this.pagination.page = response.data.page            
        this.pagination.num_page = response.data.num_page

        if(this.pagination.count > 0) {
          this.successIsActive = true
          this.searchIsActive = true
        } else {
          this.warningIsActive = true
          this.searchIsActive = false
        }
      })
      .catch((error) => {
        console.log(error)
        this.dangerIsActive = true  
        this.successIsActive = false  
        this.warningIsActive = false
        this.searchIsActive = false
        this.isLoadin = false
      })
      .finally(() => {
        this.isLoadin = false
      })
    },
    loadPages () {
      this.isLoadin = true
      axios.get(`${BASE_URL}/api/v1/profissionais-resume/?page=${this.currentPage}`, {params: this.axiosParams()})
      .then(response => {        
        this.profissionais = response.data.results       
      })        
      .catch((error) => {
        console.log(error)
        this.isLoadin = false
      })
      .finally(() => {
        this.isLoadin = false

      })
    }
  }
}
</script>

<style scoped>
.font-template {
  font-family: montserrat;
}

.results {
  display: flex;
  align-items: center;
}

.pagination {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
