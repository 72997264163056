<template>
  <div class="form-rating">
    <b-overlay :show="isLoadin" rounded="sm">
      <div :aria-hidden="isLoadin ? 'true' : null">

        <div class="title-text">{{ title }}</div>

        <p class="note">{{ info }}</p>
        
        <alerts-component
          v-if="successEvaluation"
          type="success"
          message="Obrigado por sua avaliação!" 
        ></alerts-component>

        <alerts-component
          v-if="errorEvaluation"
          type="danger"
          message="Houve algum erro ao enviar sua avaliação! Por favor, tente mais tarde!" 
        ></alerts-component>

        <b-form class="form-evaluation" v-if="!successEvaluation" @submit.prevent="sendEvaluation">

          <h5>Avaliação</h5>

          <b-form-rating v-model="form.rating" color="#ff00ff" class="mb-2 mx-auto rating" :disabled="errorEvaluation"></b-form-rating>
       
          <!-- <b-form-input
            class="fields"
            v-model="form.title"
            placeholder="Título"            
            :disabled="errorEvaluation"
          ></b-form-input> -->

          <b-form-textarea
            class="mx-auto comment-box"            
            v-model="form.comment"
            placeholder="Conte como foi sua experiência."
            :disabled="errorEvaluation"
            rows="4"
            max-rows="7"
          ></b-form-textarea>
          
          <b-button class="button" type="submit" :disabled="evaluationVerify || errorEvaluation">Enviar</b-button>
        </b-form>
        
      </div>
    </b-overlay>
  </div>
</template>


<script>
import AlertsComponent from '../../components/AlertsComponent'
import axios from 'axios'
import { BASE_URL } from '../../services/http-common'

export default {
  name: 'evaluation-component',
  components: {
    AlertsComponent
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    info: {
      type: String,
      required: false,
      default: ''
    },
    clientId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({
    form: {
      rating: 0,
      title: '',      
      comment: ''      
    },    
    isLoadin: false,
    disableEvaluatin: false,        
    successEvaluation: false,
    errorEvaluation: false
  }),
  methods: {
    sendEvaluation() {     

      this.isLoadin = true
      this.disableEvaluatin = true   

      axios.post(`${BASE_URL}/api/v1/avaliacoes/`, {
          star: this.form.rating,
          title: this.form.title,
          comment: this.form.comment,
          client: this.clientId     
      })
      .then(response => {          
        if(response.status == 201) {
          this.successEvaluation = true
        }
      })
      .catch(error => {
        console.log(error)
        this.errorEvaluation = true
      })
      .finally(() => {
        this.isLoadin = false
        this.showForm = true
        this.rating = 0
      })

    }
  },
  computed: {
    evaluationVerify () {
      if((this.form.rating > 0) && (this.form.comment != '')) {
        return false
      }
      return true
    }
  }
};
</script>


<style scoped>
.title-text {
  font-size: 1.0em;
  border: 1px solid #8f8f8f;
  border-radius: 5px;
  padding: 10px 5px 10px 5px;
  box-shadow: 2px 2px 4px #8f8f8f;
  margin-bottom: 20px;
}

.form-evaluation {  
  border: 1px solid #8f8f8f;
  border-radius: 5px;
  padding: 0px 5px 0px 5px;
  box-shadow: 2px 2px 4px #8f8f8f;
  margin-bottom: 10px;
}

h5 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.fields {
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-rating {
  font-family: montserrat;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
}

.button {
  font-weight: bold;
  font-size: 1.0em;
  color: #f4f0e3;
  background: #3cbca5;
  border: 0px solid #3cbca5;
  margin-top: 30px;
  margin-bottom: 15px;
}

.button:hover {
  color: #f4f0e3;
  background: #ffb84d;
  margin-top: 30px;
  margin-bottom: 15px;
}

.message-text {
  color: #ff00ff;
}

.note {
  font-size: 12px;
  font-style: italic;
}
.rating-position {
  align-content: center;
}

.rating {
  max-width: 250px;
  align-content: center;  
}

.comment-box {
  max-width: 600px;
}


</style>