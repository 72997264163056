<template>
  <header>    
    <b-navbar class="navbar" toggleable="lg" type="dark">
      <b-link :to="{ name: 'home' }" class="text-nav">
        <b-navbar-brand>
          <img id="logo" class="img-fluid" src="../../assets/img/gp.png" alt="Guia Profissional">
          <!-- <img id="logo" class="img-fluid" src="../../assets/img/gp.png" alt="Guia Profissional"> Guia Profissional<br /> <small style="float:right; margin-top:-25px;">by CRMV-GO</small> -->
        </b-navbar-brand>
      </b-link>          
      
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <div class="nav-custom">
          <b-navbar-nav>                      
            <b-link :to="{ name: 'home' }" class="text-nav">Home</b-link>          
          </b-navbar-nav>
        </div>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>           
            <!-- <b-button size="md" class="my-2 my-sm-0 botao">LOGIN</b-button> -->
            <a class="btn botao" :href="`${baseUrl}/painel#/login`" target="_blank">Conta/Entrar</a>
            <!-- <b-button href="#">I am a Link</b-button> -->
          </b-nav-form>          
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>


<script>
import { BASE_URL } from '../../services/http-common'


export default {
  name: "header-component",
  data: () => ({
    baseUrl: BASE_URL
  })
};
</script>

<style scoped>

header {
  margin-bottom: 30px;
}

.navbar {
  background-color: transparent;
}

.navbar-brand{
    padding: 0;
    color: #f4f0e3;
}
.navbar-brand:hover{
    color: #f4f0e3;
}

#navbar-links a {
   color: #f4f0e3; 
}

#logo {
  width: 200px;
}

.navbar-expand-lg .navbar-nav .nav-link{
 padding: 1rem .8rem;   
}


header {
  font-family: montserrat;
}

@media screen and (max-width: 576px) {
  .nav-custom {
    margin-left: 0px;
    border-bottom: 2px solid #3cbca5;
    width: 350px;
  }
  .botao {
    color: #f4f0e3;
    font-weight: 600;
    max-height: 35px;
    padding: 5px 5px 20px 5px; 
    background-color: #3cbca5;
    margin-top: 10px;
    border-radius: 6px;        
  }
  .botao:hover {
    color: #f4f0e3;
    background-color: #547c71;
  }
}

@media screen and (min-width: 576px) {
  .nav-custom {
    margin-left: 40px;
    border-bottom: 2px solid #3cbca5;
    width: 780px;
  }
  .botao {
    color: #f4f0e3;
    font-weight: 600;
    max-height: 35px;
    padding: 5px 5px 20px 5px;
    background-color: #3cbca5;
    margin-top: 10px;
    border-radius: 6px;
    /* max-width: 80px;     */
  }
  .botao:hover {
    color: #f4f0e3;
    background-color: #ffb84d;
  }
}

.text-nav {
  color: #3cbca5;
  font-size: 1.6em;
  font-weight: bold;
  text-decoration: none;  
}
.text-nav:hover {
  color: #ffb84d;
  transition: color 0.2s;
}
.nav-collapse-color {
  color: #3cbca5;
}

</style>