<template>
  <div id="app" class="vld-parent">
    <loading :active.sync="show" 
        :can-cancel="true" 
        :on-cancel="onCancel"
        :is-full-page="true"
        color="#00cc99"
        loader="dots"
    ></loading>
      
    <transition name="fade">
      <div v-if="!show">
        <header-component></header-component>
        <router-view></router-view>
      </div>
    </transition>
  </div>
</template>

<script>
import HeaderComponent from './components/Layouts/HeaderComponent'
import Loading from 'vue-loading-overlay'    
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: "App",
  components: {
    HeaderComponent,
    Loading    
  },
  data: () => ({
    show: true
  }),
  mounted() {
    this.show = false
  },
  methods: {
    onCancel() {
      console.log('Loader cancelado pelo usuário.')
    }
  }
};
</script>

<style>

body {
  margin: 0;
  padding: 0;
  background: url("./assets/img/fundo.png") no-repeat center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-backgroud-size: cover;
  -o-backgroud-size: cover;
  font-family: montserrat;  
}

/* #app {
  font-family: "Roboto Light", sans-serif;
} */

.container-fluid {
  margin-top: 20px;
  padding: 0px;
}

.row {
  margin: 0;
}
.container {
  padding: 0px 0px;
}
p,
li {
  color: #444;
  font-size: 14px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
