import { render, staticRenderFns } from "./SearchProfissional.vue?vue&type=template&id=7d98fb81&scoped=true&"
import script from "./SearchProfissional.vue?vue&type=script&lang=js&"
export * from "./SearchProfissional.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d98fb81",
  null
  
)

export default component.exports