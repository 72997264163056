const getters = {
    getProfile(state) {
      return state.profile
    },
    getUserId(state) {
      if(state.userId == null || state.userId == undefined) {
        return 0
      }
      return state.userId
    },
    getIsActivatedAccount(state) {
      if(state.isActivatedAccount == null || state.isActivatedAccount == undefined) {
        return false
      }
      return state.isActivatedAccount
    },
    getIsAuthenticated(state) {      
      return state.isAuthenticated
    },
    getIsCompletedRegistration(state) {      
      return state.isCompletedRegistration
    },
    getPropertyCode(state) {      
      return state.propertyCode
    }
  }
  
  export default getters
  