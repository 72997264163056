<template>
  <div>
    
  </div>
</template>

<script>
import profileClick from '../services/profileclick.data.service'

export default {
  name: 'profile-click',
  data: () => ({
    form: {
      element: '',
      client: 0
    }
  }),
  methods: {
    sendForm(element, client) {
      this.form.element = element
      this.form.client = client
      profileClick.new(this.form)
      .then(() => {        
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
