<template>
  <div class="font-template">
    <b-card no-body class="overflow-hidden card-custom effect">
      <b-row no-gutters>
        <b-col md="4" sm="4" class="img-arrange">
          <b-img
          class="img-custom"
            thumbnail
            rounded
            fluid-grow
            right        
            :src="`${BASE_URL}/media/${profissional.foto}` | defaultImage"            
            alt="Imagem de pessoa padrão"
          ></b-img>
        </b-col>
        <b-col md="8" sm="8">
          <b-card-body>
            <b-card-title class="card-title">{{ profissional.nome_completo | upperCaseText }}</b-card-title>
            <b-card-text>
              <p>
                <i class="fas fa-user-md fa-1x mr-1"></i><strong>Profissão:</strong>
                {{ profissional.profissao | completeProf }}
              </p>
              <p>
                <i class="fas fa-id-card fa-1x mr-1"></i><strong>Nº do registro no CRMV:</strong>
                {{ profissional.crmv }}
              </p>
              <p>     
                <span class="social-data" v-if="profissional.telefone_celular">
                  <a @click="sendClick('SP', profissional.id)" target="_balnk" :href="`tel:${ profissional.telefone_celular }`">                    
                    <i class="fas fa-mobile fa-1x mr-1"></i>
                    {{ profissional.telefone_celular | nullToString | VMask('(##) # ####-####') }}
                  </a>
                </span>
                <span class="social-data" v-if="profissional.whatsapp">
                  <a @click="sendClick('WA', profissional.id)" target="_balnk" :href="`https://wa.me/55${ profissional.whatsapp }`">
                    <i class="fab fa-whatsapp-square fa-1x mr-1"></i>
                    {{ profissional.whatsapp }}
                  </a>
                </span>
              </p>
              <p>
                <span class="social-data" v-if="profissional.telefone_fixo">
                  <a @click="sendClick('PH', profissional.id)" target="_balnk" :href="`tel:${ profissional.telefone_fixo }`">                    
                    <i class="fas  fa-phone fa-1x mr-1"></i>
                    {{ profissional.telefone_fixo | nullToString | VMask('(##) ####-####') }}
                  </a>    
                </span>
              </p>
              <p>
                <strong>Cidade:</strong>
                {{ getPrincipalCity }}
              </p>      
            </b-card-text>

            <prof-detail-component
              :profissional-id="profissional.id"
              buttonTitle="Saiba mais sobre mim"
            ></prof-detail-component>

            <profile-click
              ref="profileClick"
            ></profile-click>
            
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import ProfDetailComponent from './ProfDetailComponent'
import ProfileClick from '../../ProfileClick'
import { BASE_URL } from '../../../services/http-common'

const urlImgPerson = BASE_URL + '/static/website/images/person.png'

export default {
  name: 'prof-card-resume-component',
  components: {
    ProfDetailComponent,
    ProfileClick
  },
  props: {
    profissional: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      BASE_URL:BASE_URL
    };
  },
  computed: {
    getPrincipalCity() {
      const { enderecos } = this.profissional;
      let nome = '';
      if(enderecos.length > 0) {
        enderecos.map(endereco => {
          nome = endereco.is_principal ? endereco.cidade : ''
        });
      }
      return nome;
    },
  },
  methods: {
    sendClick(element, client) {      
      this.$refs.profileClick.sendForm(element, client)      
    }
  },
  filters: {
    completeProf(value) {
      if (value == 'V') {
        return 'Médico(a) Veterinário(a)';
      } else if (value == 'Z') {
        return 'Zootecnista';
      } else if (value == 'A') {
        return 'Auxiliar Veterinário(a)';
      }
      return ''
    },
    defaultImage(value) {
      if (value) {
        return value;
      } else {
        return urlImgPerson;
      }
    },
    upperCaseText(value) {
      return value.toUpperCase();
    },
    nullToString(value) {
      if (value == null) {
        return '';
      } else {
        return value;
      }
    }
  }
};
</script>


<style scoped>

.card-title {
  font-size: 0.9em;
  font-weight: bold;
}

a {
  color: #3cbca5;
  transition: color 0.2s;
}
a:hover {
  color: #ffb84d;
  text-decoration: none;
}

.font-template {
  font-family: montserrat;
}

.img-arrange {
  text-align: center;
}

/* ===== Card effect ======= */
.effect {
  border: 1px solid #8f8f8f;
  -webkit-box-shadow: 4px 4px 6px #8f8f8f;
  -moz-box-shadow: 4px 4px 6px #8f8f8f;
  box-shadow: 4px 4px 6px #8f8f8f;
}
/* ===== End card effect ======= */

@media screen and (max-width: 349px) {
  .card-custom {
    max-width: 500px;
    height: auto;
    padding: 5px 5px 5px 5px;
    margin: 5px 15px 5px 15px;
    border-radius: 5px;
  }
  .img-custom {
    /* background-color: red; */
    max-width: 220px;
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 30px;
    width: 50%;
  }
}

@media screen and (min-width: 350px) and (max-width: 575px) {
  .card-custom {
    max-width: 500px;
    height: auto;
    padding: 5px 5px 5px 5px;
    margin: 5px 15px 5px 15px;
    border-radius: 5px;
  }
  .img-custom {
    /* background-color: #24d1ac; */
    max-width: 220px;
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 55px;
    width: 50%;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .card-custom {
    max-width: 450px;
    height: auto;
    padding: 5px 5px 5px 10px;
    margin: 5px 15px 5px 15px;
    border-radius: 5px;
  }
  .img-custom {
    /* background-color: #a36fb8; */
    max-width: 160px;
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: auto;
    align-items: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .card-custom {
    max-width: 450px;
    height: auto;
    padding: 5px 5px 5px 10px;
    margin: 5px 15px 5px 15px;
    border-radius: 5px;
  }
  .img-custom {
    /* background-color: #845e2b; */
    max-width: 160px;
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: auto;
    align-items: center;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .card-custom {
    max-width: 450px;
    /* max-height: 350px; */
    height: 240px;
    padding: 5px 5px 10px 10px;
    margin: 5px 15px 5px 15px;
    border-radius: 5px;
  }
  .img-custom {
    /* background-color: #382b84; */
    max-width: 160px;
    margin-top: 20px;
    margin-bottom: auto;
    margin-left: 5px;
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .card-custom {
    max-width: 540px;
    max-height: 250px;
    min-width: 520px;
    min-height: 250px;
    /* height: auto; */
    padding: 5px 5px 5px 5px;
    margin: 5px 15px 5px 15px;
    border-radius: 5px;
  }
  .img-custom {
    /* background-color: #c71c1c; */
    max-width: 160px;
    margin-top: 20px;
    margin-bottom: auto;
    margin-left: 10px;
    align-items: center;
  }
}

p {
  margin-top: 3px;
  margin-bottom: 3px;
}

.rating-view {
  margin-bottom: 8px;
}

.social-data {
  margin: 0px 0px 0px 0px;
}
</style>