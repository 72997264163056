<template>
  <div class="font-template">
    <b-button @click="getDetailProf" block size="sm" class="button">{{ buttonTitle }}</b-button>

    <b-modal v-model="modalShow" ok-only centered size="lg" :title="profissional.nome_completo">    

      <b-overlay :show="isLoadin" rounded="sm">
        <b-tabs
          content-class="mt-3"
          :aria-hidden="isLoadin ? 'true' : null"
          active-nav-item-class="font-weight-bold text-uppercase text-danger"          
        >
          <b-tab active>
            <template v-slot:title>
              <b-icon icon="person-fill"></b-icon>Sobre mim
            </template>
            <b-row>
              <b-col md="2" sm="2" class="img-arrange">
                <b-img
                  class="img-custom"
                  fluid-grow
                  thumbnail
                  right
                  rounded
                  :src="`${BASE_URL}/media/${profissional.foto}` | defaultImage"
                  alt="Imagem de pessoa padrão"
                ></b-img>
              </b-col>
              <b-col md="10" sm="10" class="font-template">
                <p class="bio-texts">
                  <i class="fas fa-user-edit fa-1x mr-1"></i><span class="bio">Bio:</span>
                  {{ profissional.sobre_mim }}
                </p>
                <!-- <p style="margin-bottom: 20px">
                  <i class="fas fa-calendar-alt fa-1x mr-1"></i><strong>Idade:</strong>
                  {{ profissional.data_nascimento | ageCalc }} anos
                </p> -->
                <div v-for="(endereco, index) in profissional.enderecos" :key="index">
                  <div v-if="endereco.is_public">
                    <h6><i class="fas fa-map-marked-alt fa-1x mr-1">
                       </i><strong>Endereço:</strong></h6>
                    <p>
                      <strong>Logradouro:</strong>
                      {{ endereco.logradouro }}
                    </p>
                    <p v-if="endereco.complemento">
                      <strong>Complemento:</strong>
                      {{ endereco.complemento }}
                    </p>
                    <p v-if="endereco.numero">
                      <strong>Número:</strong>
                      {{ endereco.numero }}
                    </p>
                    <p>
                      <strong>Bairro:</strong>
                      {{ endereco.bairro }}
                    </p>
                    <p>
                      <strong>Cidade:</strong>
                      {{ endereco.cidade.nome }}
                    </p>
                    <p>
                      <strong>UF:</strong>
                      {{ endereco.estado.uf }}
                    </p>
                    <p v-if="endereco.local_googlemaps"> 
                      <span class="social-data">                                            
                        <a target="_blank" :href="endereco.local_googlemaps"><unicon name="map-marker" fill="red" height="50px" width="50px"></unicon></a>
                      </span>
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab @click="sendClick('PR', profissional.id)">
            <template v-slot:title>
              <b-icon icon="briefcase-fill"></b-icon> Profissional
            </template>
            <b-row class="font-template">
              <b-col>
                <h4>Dados profissionais e acadêmicos</h4>
                <p>
                  <i class="fas fa-user-md fa-1x mr-1"></i> <strong>Profissão:</strong>
                  {{ profissional.profissao | completeProf }}
                </p>
                <p>
                  <i class="fas fa-id-card fa-1x mr-1"></i> <strong>Registro Profissional (CRMV):</strong>
                  {{ profissional.crmv }}
                </p>
                <p>
                  <i class="fas fa-user-graduate fa-1x mr-1"></i> <strong>Currículo Lattes: </strong>
                  <a :href="profissional.lattes">{{ profissional.lattes }}</a>
                </p>
                <p style="margin-bottom: 20px;">
                  <i class="fas fa-building fa-1x mr-1"></i> <strong>Empresa atual:</strong>
                  {{ profissional.empresa_atual }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <prof-card-atuacao-component
                  :atuacoes="profissional.atuacoes_profissionais"
                ></prof-card-atuacao-component>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <b-icon icon="at"></b-icon>Contato
            </template>

            <b-row class="font-template">

              <b-col md="4" sm="12">
                <span class="social-data" v-if="profissional.telefone_celular">
                    <a @click="sendClick('SP', profissional.id)" target="_balnk" :href="`tel:${ profissional.telefone_celular }`">
                      <i class="fas fa-mobile fa-1x mr-1"></i>
                      {{ profissional.telefone_celular | nullToString | VMask('(##) # ####-####') }}
                    </a>
                  </span>
              </b-col>

              <b-col md="4" sm="12">
                <span class="social-data" v-if="profissional.telefone_fixo">
                    <a @click="sendClick('PH', profissional.id)" target="_balnk" :href="`tel:${ profissional.telefone_fixo }`">
                      <i class="fas fa-phone fa-1x mr-1"></i>
                      {{ profissional.telefone_fixo | nullToString | VMask('(##) ####-####') }}
                    </a>    
                </span>
              </b-col>
            
              <b-col md="4" sm="12">
                <span class="social-data" v-if="profissional.whatsapp">
                    <a @click="sendClick('WA', profissional.id)" target="_balnk" :href="`https://wa.me/55${ profissional.whatsapp }`">
                      <i class="fab fa-whatsapp-square fa-1x mr-1"></i>
                      {{ profissional.whatsapp }}
                    </a>
                </span>
              </b-col>

            </b-row>              

            <b-row class="font-template">

              <b-col md="6" sm="12">
                <span class="social-data" v-if="profissional.email_comercial">
                  <a @click="sendClick('EM', profissional.id)" :href="`mailto:${profissional.email_comercial}`">
                    <i class="fas fa-envelope fa-1x mr-1"></i>
                    {{ profissional.email_comercial }}
                  </a>
                </span>
              </b-col>

              <b-col md="6" sm="12">
                <span class="social-data" v-if="profissional.website">
                  <a @click="sendClick('WS', profissional.id)" :href="profissional.website">
                    <i class="fas fa-globe fa-1x mr-1"></i>
                    {{ profissional.website }}
                  </a>
                </span>
              </b-col>

            </b-row>

            <b-row>
              <b-col>                
                <span class="social-data" v-if="profissional.linkedin">
                  <a @click="sendClick('LE', profissional.id)" :href="profissional.linkedin">
                    <i class="fab fa-linkedin fa-2x mr-1"></i>
                  </a>
                </span>            
                        
                <span class="social-data" v-if="profissional.facebook">
                  <a @click="sendClick('FB', profissional.id)" :href="profissional.facebook">                    
                    <i class="fab fa-facebook fa-2x mr-1"></i>
                  </a>
                </span>            
                          
                <span class="social-data" v-if="profissional.instagram">
                  <a @click="sendClick('IG', profissional.id)" :href="profissional.instagram">                    
                    <i class="fab fa-instagram fa-2x mr-1"></i>
                  </a>
                </span>            
                            
                <span class="social-data" v-if="profissional.twitter">
                  <a @click="sendClick('TW', profissional.id)" :href="profissional.twitter">                    
                    <i class="fab fa-twitter fa-2x mr-1"></i>
                  </a>
                </span>

              </b-col>                         
            </b-row>

          </b-tab>

          <b-tab title="Avaliação">
            <template v-slot:title>
              <b-spinner type="grow" small></b-spinner>
              <strong>Avalie-me!</strong>
            </template>
            <b-row>
              <b-col>
                <evaluation-component
                  title="Compartilhe sua experiência para contribuir na melhoria da prestação do serviço do profissional*:"
                  info="*Comunicamos que essa avaliação é anônima e ficará disponível apenas para o profissional e CRMV-GO. Além disso, este não é um canal de denúncia."
                  :client-id="profissional.id"                
                ></evaluation-component>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-overlay>

      <profile-click
        ref="profileClick"
      ></profile-click>

    </b-modal>
  </div>
</template>

<script>
import ProfCardAtuacaoComponent from './ProfCardAtuacaoComponent'
import EvaluationComponent from '../EvaluationComponent'
import ProfileClick from '../../ProfileClick'
import axios from 'axios'
import { BASE_URL } from '../../../services/http-common'

const urlImgPerson = BASE_URL + '/static/website/images/person.png'

export default {
  name: 'prof-detail-component',
  components: {
    ProfCardAtuacaoComponent,
    EvaluationComponent,
    ProfileClick 
  },
  props: {
    profissionalId: {
      type: Number,
      required: true,
      default: 0
    },
    buttonTitle: {
      type: String,
      default: 'Clique aqui'
    }
  },
  data: () => ({
    BASE_URL: BASE_URL,
    modalShow: false,
    profissional: {
      user: {
        email: ''
      }
    },
    enderecos: '',
    isLoadin: false
  }),
  methods: {
    sendClick(element, client) {      
      this.$refs.profileClick.sendForm(element, client)      
    },
    getDetailProf() {
      this.modalShow = !this.modalShow;
      this.isLoadin = true;
      // busca o profissional
      axios.get(
          `${BASE_URL}/api/v1/profissionais-detail/${this.profissionalId}/`,
          {}
        )
        .then(response => {
          this.profissional = response.data;    
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadin = false;
        });
    }
  },
  filters: {
    completeProf(value) {
      if (value == 'V') {
        return 'Médico(a) Veterinário(a)';
      } else if (value == 'Z') {
        return 'Zootecnista';
      } else if (value == 'A') {
        return 'Auxiliar Veterinário(a)';
      }      
      return ''
    },
    defaultImage(value) {
      if (value) {
        return value;
      } else {
        return urlImgPerson;
      }
    },
    nullToString(value) {
      if (value == null) {
        return '';
      } else {
        return value;
      }
    },
    ageCalc(value) {
      if (value != null) {
        let presentDate = new Date();
        let birday = new Date(value);
        return presentDate.getFullYear() - birday.getFullYear();        
      } else {
        return 0;
      }
    }
  }
}
</script>

<style scoped>
.button {
  color: #3cbca5;
  background: #fff;
  border: 1px solid #3cbca5;
}

.button:hover {
  color: #fff;
  background: #3cbca5;
  border: 1px solid #3cbca5;
  transition: color 0.2s;
}

a {
  color: #3cbca5;
  transition: color 0.2s;
}
a:hover {
  color: #ffb84d;
  text-decoration: none;
}

.font-template {
  font-family: montserrat;
}


p {
  margin: 5px 5px 0px 0px;
}

.bio-texts {
  margin-bottom: 15px;
}

.img-arrange {
  text-align: center;  
}

@media screen and (max-width: 576px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 110px;
    width: 50%;
  }  
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }  
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .img-custom {
    max-width: 150px;  
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

.social-data {
  margin: 10px 10px 10px 15px;
}

.bio {
  font-size: 1.2em;
  font-weight: bold;
}
</style>