<template>
  <div class="mt-5 pt-5 pb-5 footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-xs-12 about-company">
          <h2 class="text-center">Atenção!</h2>
          <p class="pr-5 text-justify">
            Os dados e contatos apresentados são de inteira responsabilidade dos profissionais e empresas que se cadastrarem no Guia. É vedado ao CRMV-GO divulgar dados pessoais dos profissionais.
          </p>
        
        </div>
        <div class="col-lg-3 col-xs-12 links">
          <h4 class="mt-lg-0 mt-sm-3">Links</h4>
          <ul class="m-0 p-0">
            <li><a href="https://www.crmvgo.org.br">- CRMV-GO</a></li>
            <li><a href="https://www.cfmv.gov.br">- CFMV</a></li>
            <li class="social">
              <span><a href="https://pt-br.facebook.com/CRMVGO" target="_blank"><i class="fab fa-facebook fa-2x"></i></a></span>
              <span><a href="https://www.instagram.com/crmvgo/" target="_blank"><i class="fab fa-instagram fa-2x"></i></a></span>
              <span><a href="https://twitter.com/CRMVGO" target="_blank"><i class="fab fa-twitter fa-2x"></i></a></span>              
            </li>
          </ul>          
        </div>
        <div class="col-lg-4 col-xs-12 location">
          <h4 class="mt-lg-0 mt-sm-4">Dúvidas?</h4>
          <p class="contact">Entre em contato:</p>
          <p class="mb-0"><i class="fa fa-phone mr-3"></i>(62) 3602-1315</p>
          <p><i class="fa fa-envelope mr-3"></i><a href="mailto:guiaprofissional@crmvgo.org.br">guiaprofissional@crmvgo.org.br</a></p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col copyright">
          <p>             
            <small class="text-white-50"> &copy; {{ getCurrentYear }} Desenvolvido por <a href="https://www.crmvgo.org.br" target="_black">CRMV-GO</a></small>
            <small style="float: right;"><a href="https://crmvgo.org.br/guiaprofissional/admin/" target="_blank">ADM</a></small>            
          </p>
          <!-- <p class="adm-link"><a href="https://crmvgo.org.br/guiaprofissional/admin/" target="_blank">ADM</a></p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-component",
  data: () => ({}),
  computed: {
    getCurrentYear() {
      let currentDate = new Date();
      return currentDate.getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
$base-color: #547c71;
$links-color: #ffb84d;

.footer {
  background: $base-color;
  color: white;
  font-family: montserrat;

  .links {
    ul {
      list-style-type: none;
    }
    li a {
      color: white;
      transition: color 0.2s;
      &:hover {
        color: $links-color;
        text-decoration: none;
      }
    }
    .social {
      margin-top: 10px;    
      margin-bottom: 20px;
    }
    span {
      margin-right: 10px;;
    }
  }
  .about-company {
    i {
      font-size: 25px;
    }
    p {
      color: white;
    }
    a {
      color: white;
      transition: color 0.2s;
      &:hover {
        color: $links-color;
        text-decoration: none;
      }
    }
  }
  .location {
    i {
      font-size: 18px;
    }
    p {
      color: white;
    }
    a {
      color: white;
      transition: color 0.2s;
      &:hover {
        color: $links-color;
        text-decoration: none;
      }
    }
    .contact {
      margin-bottom: 5px;
    }
  }
  .copyright p {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    a {
      color: white;
      transition: color 0.2s;
      &:hover {
        color: $links-color;
        text-decoration: none;
      }
    }
  }
}
</style>>
