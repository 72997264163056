<template>
  <div class="text">
    <b-overlay :show="showOverlay" rounded="sm">
      <div class="container" :aria-hidden="showOverlay ? 'true' : null">        

        <alerts-component
          v-if="alert.message"
          :type="alert.type"
          :message="alert.message"
        >
        </alerts-component>        
        
        <b-row
          v-if="getIsCompletedRegistration"
          align-h="center"
          class="text-center"      
          >
          <div class="d-flex justify-content-center">
            <b-card
              title="Você finalizou seu cadastro com sucesso!"
              style="max-width: 20rem;"
              class="mb-2"
            >
              <b-img center src="../assets/img/tick_128px.png" alt="Center image" width="100px"></b-img>
              <b-card-text>
                Mais tarde você poderá entrar em seu painel de controle e acompanhar o status da aprovação do seu cadastro.
              </b-card-text>

              <b-button :href="`${baseUrl}/painel#/login`" variant="primary">Painel</b-button>
            </b-card>
          </div>
        </b-row>

        <div v-if="!accountHasBeenActivated && !getIsCompletedRegistration && !error" >

          <b-card class="text"> 
            <h5>Escolha o seu perfil:</h5>
            <b-form-radio-group v-model="profile" name="profile" @input="changeProfile">
              <b-form-radio value="profissional">Profissional</b-form-radio>
              <b-form-radio value="empresa">Empresa</b-form-radio>            
            </b-form-radio-group>       
          </b-card>        

          <complete-register-profissional
            v-if="profile == 'profissional'"
            :profile="profile"
          ></complete-register-profissional>

          <complete-register-empresa
            v-if="profile == 'empresa'"
            :profile="profile"
          ></complete-register-empresa>

        </div>
      </div>
    </b-overlay>    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AlertsComponent from '../components/AlertsComponent'
import axios from 'axios'
import { BASE_URL } from '../services/http-common'

export default {  
  name: 'account-activate',
  components: {
    CompleteRegisterProfissional: () => import('../components/CompleteRegister/Profissional/CompleteRegisterProfissional'),
    CompleteRegisterEmpresa: () => import('../components/CompleteRegister/Empresa/CompleteRegisterEmpresa'),
    AlertsComponent
  },
  data: () => ({
    isAuthenticated: false,
    accountHasBeenActivated: false,
    showOverlay: false,
    userId: null,
    profile: 'profissional',
    baseUrl: BASE_URL,
    error: false,
    alert: {
      message: '',
      type: ''
    }
  }), 
  mounted() {
    this.changeProfile();
    this.activateAccount();
  },
  computed: {
    ...mapGetters([
      'getIsCompletedRegistration'      
    ])
  },
  methods: {
    changeProfile() {
      this.$store.commit('SET_PROFILE', this.profile)
    },
    activateAccount() {
      this.error = false;
      this.showOverlay = true
      const params = this.$route.query    
      axios({
        method: 'post',
        url: `${BASE_URL}/api/jwtauth/activation/`,
        data: {
          uid: params.uid,
          token: params.token
        }
      })
      .then(response => {                    
        if(response.status == 200) {          
          this.isAuthenticated = true
          this.$store.commit('SET_IS_AUTHENTICATED', true)
          this.userId = response.data.userId
          this.$store.commit('SET_USER_ID', this.userId)
          // Mensagem
          this.alert.message = 'E-mail confirmado com sucesso!'
          this.alert.type = 'success'
        }
      })
      .catch(error => {
        if(error.response.status == 400){          
          // Mensagem
          this.error = true;
          this.alert.message = 'Usuário não encontrado!'
          this.alert.type = 'danger'

        } else if(error.response.status == 403) {
          // Mensagem
          this.error = true;
          this.alert.message = 'O cadastro do usuário já foi completado e ativadado!'
          this.alert.type = 'warning'

        } else if(error.response.status == 404){          
          // Mensagem
          this.error = true;
          this.alert.message = 'Faltam os parâmetros uid e token'
          this.alert.type = 'warning'

        } else if(error.response.status == 406){
          this.error = true;
          this.accountHasBeenActivated = true                  
          this.$store.commit('SET_IS_ACTIVATED_ACCOUNT', true)  
          // Mensagem
          this.alert.message = `Esse link de ativação expirou! Caso esteja tendo problemas para completar seu cadastro, clique <a href="${BASE_URL}/#/resending-activation-email">aqui</a> para enviarmos um novo link de ativação para o seu e-mail.`
          this.alert.type = 'warning'

        }  else if(error.response.status == 500){
          // Mensagem
          this.error = true;
          this.alert.message = 'Desculpe... Erro no servidor!'
          this.alert.type = 'warning'          
        }        
      })
      .finally(() => {
        this.showOverlay = false        
      })
    }
  }
}
</script>

<style scoped>
  .text {
    font-family: montserrat;
  }
</style>