<template>
  <div class="font-template">    
    <b-alert
      :variant="type"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert=false"
    >
      <b-icon v-if="type == 'danger'" icon="exclamation-octagon-fill" variant="danger"></b-icon>
      <b-icon v-if="type == 'warning'" icon="exclamation-triangle-fill" variant="warning"></b-icon>
      <b-icon v-if="type == 'success'" icon="check-all" variant="success"></b-icon> <span v-html="message"></span> {{ results }}
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "alerts-component",  
  props: {
    type: {
      type: String,
      default: 'success'
    },
    results: {      
      default: ''
    },
    message: {
      type: String,
      default: 'Alerta!'
    },
  },
  data() {
    return {
      showDismissibleAlert: true
    };
  }
};
</script>

<style scoped>
.font-template {
  font-family: montserrat;
}
</style>
